<template>
    <div class="main shops-page">
        <section class="main-width ">
            <div class="row no-gutters res-sides">
                <div class="col-12 col-lg-2">
                    <div class="left-side">
                        <ul>
                            <li><router-link active-class="active" to="/plans"> {{$t('navigation.purchase_plan')}} </router-link></li>
                            <li><router-link  to="/tenders">{{$t('navigation.tender')}}</router-link></li>
                            <li><router-link  to="/lots">{{$t('navigation.lot')}}</router-link></li>
                            <li><router-link  to="/contracts">{{$t('navigation.contracts')}}</router-link></li>
                            <li v-if="isAuth"><router-link  to="/favourite">{{$t('navigation.favourite')}}</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-lg-10">
                    <router-view></router-view>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import {mapGetters} from 'vuex'

export default {
    name: 'purchase',
    computed: {
        ...mapGetters([
            'isAuth'
        ]),
    }
}
</script>